var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-detail" },
    [
      _vm.content
        ? [
            _c("HeadImage", { attrs: { src: _vm.content["image_url"] } }),
            _c("div", { staticClass: "c-section c-section--mb24" }, [
              _c("h1", { staticClass: "c-title4" }, [
                _vm._v(" " + _vm._s(_vm.content["title"]) + " "),
              ]),
              _c("p", { staticClass: "c-lead" }, [
                _vm._v(" " + _vm._s(_vm.content["catchphrase"]) + " "),
              ]),
              _c("ul", { staticClass: "c-list c-list--grey01" }, [
                _vm.eventSchedules && _vm.eventSchedules.length > 0
                  ? _c(
                      "li",
                      { staticClass: "c-list__item" },
                      [
                        _c("Icon", { attrs: { name: "calendar" } }),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getPeriod(
                                  _vm.eventSchedules[0]["start_at"],
                                  _vm.eventSchedules[0]["end_at"]
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.content["venue_address"] &&
                _vm.content["venue_address"].length > 0
                  ? _c(
                      "li",
                      { staticClass: "c-list__item" },
                      [
                        _c("Icon", { attrs: { name: "pin" } }),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.content["venue_address"]) + " "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "c-section c-section--mb24" }, [
              _c("h3", { staticClass: "c-title6" }, [_vm._v("イベント情報")]),
              _c("p", [_vm._v(" " + _vm._s(_vm.content["description"]) + " ")]),
            ]),
            _vm.content["additional_images"] &&
            _vm.content["additional_images"].length > 0
              ? _c(
                  "div",
                  { staticClass: "c-section c-section--mb32" },
                  [
                    _c(
                      "Carousel",
                      { attrs: { options: _vm.additionalImagesOptions } },
                      _vm._l(_vm.content["additional_images"], function (item) {
                        return _c(
                          "div",
                          {
                            key: item.index,
                            staticClass: "swiper-slide event-detail__slide",
                          },
                          [
                            _c("img", {
                              staticClass: "swiper-slide",
                              attrs: { src: item["image_url"], alt: "" },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.content["baristas"] && _vm.content["baristas"].length > 0
              ? _c(
                  "div",
                  { staticClass: "c-section c-section--mb32" },
                  [
                    _c("h3", { staticClass: "c-title6" }, [_vm._v("登壇者")]),
                    _c("ProCard", {
                      attrs: { content: _vm.content["baristas"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.eventSchedules && _vm.eventSchedules.length > 0
              ? _c("div", { staticClass: "c-section c-section--mb32" }, [
                  _c("h3", { staticClass: "c-title6" }, [_vm._v("開催日時")]),
                  _c(
                    "ul",
                    { staticClass: "c-list" },
                    _vm._l(_vm.eventSchedules, function (schedule) {
                      return _c(
                        "li",
                        { key: schedule.index, staticClass: "c-list__item" },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getPeriod(
                                    schedule["start_at"],
                                    schedule["end_at"]
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.content["venue_name"]
              ? _c("div", { staticClass: "c-section c-section--mb24" }, [
                  _c("h3", { staticClass: "c-title6" }, [_vm._v("開催場所")]),
                  _c("ul", { staticClass: "c-list" }, [
                    _c("li", { staticClass: "c-list__item" }, [
                      _vm._v(_vm._s(_vm.content["venue_description"])),
                    ]),
                    _c(
                      "li",
                      { staticClass: "c-list__item" },
                      [
                        _vm.googleMapUrl
                          ? _c(
                              "a",
                              {
                                staticClass: "c-addressAnchor",
                                attrs: { href: _vm.googleMapUrl },
                              },
                              [
                                _c("Icon", { attrs: { name: "pin" } }),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.content["venue_name"]) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : [
                              _c("Icon", { attrs: { name: "pin" } }),
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.content["venue_name"]) + " "
                                ),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.content["caution"]
              ? _c("div", { staticClass: "c-section c-section--mb32" }, [
                  _c("h3", { staticClass: "c-title6" }, [_vm._v("注意事項")]),
                  _c("p", {
                    staticClass: "event-detail__caution",
                    domProps: { innerHTML: _vm._s(_vm.content["caution"]) },
                  }),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }