<template>
  <div class="event-detail">
    <template v-if="content">
      <HeadImage :src="content['image_url']" />

      <div class="c-section c-section--mb24">
        <h1 class="c-title4">
          {{ content['title'] }}
        </h1>

        <p class="c-lead">
          {{ content['catchphrase'] }}
        </p>

        <ul class="c-list c-list--grey01">
          <li
            v-if="eventSchedules && eventSchedules.length > 0"
            class="c-list__item">
            <Icon name="calendar" />
            <span>
              {{
                getPeriod(
                  eventSchedules[0]['start_at'],
                  eventSchedules[0]['end_at']
                )
              }}
            </span>
          </li>

          <li
            v-if="
              content['venue_address'] && content['venue_address'].length > 0
            "
            class="c-list__item">
            <Icon name="pin" />
            <span>
              {{ content['venue_address'] }}
            </span>
          </li>
        </ul>
      </div>

      <!-- <div v-if="rewardValue" class="c-section c-section--mb32">
        <h2 class="c-title5">
          <span class="c-title5__main">
            <span class="c-title5__main__inner"> イベント参加で獲得 </span>
          </span>
        </h2>

        <div class="c-card c-card--beige03Bg">
          <p class="event-detail__mile">
            <strong>
              {{ rewardValue }}
            </strong>
            <span>{{ rewardTypeUnit }}</span>
          </p>
        </div>
      </div> -->

      <div class="c-section c-section--mb24">
        <h3 class="c-title6">イベント情報</h3>
        <p>
          {{ content['description'] }}
        </p>
      </div>

      <div
        v-if="
          content['additional_images'] &&
          content['additional_images'].length > 0
        "
        class="c-section c-section--mb32">
        <Carousel :options="additionalImagesOptions">
          <div
            v-for="item in content['additional_images']"
            :key="item.index"
            class="swiper-slide event-detail__slide">
            <img :src="item['image_url']" alt="" class="swiper-slide" />
          </div>
        </Carousel>
      </div>

      <div
        v-if="content['baristas'] && content['baristas'].length > 0"
        class="c-section c-section--mb32">
        <h3 class="c-title6">登壇者</h3>

        <ProCard :content="content['baristas']" />
      </div>

      <!-- <div
        v-if="content['products'] && content['products'].length > 0"
        class="c-section c-section--mb32">
        <h3 class="c-title6">イベント用コーヒー情報</h3>

        <ul class="c-cards c-cards--coffeeInfo">
          <li v-for="product in content['products']" :key="product.index">
            <CoffeeCard
              :href="
                $customUrlScheme.page(
                  `detail`,
                  `url=/coffee/detail/${product['id']}?event_id=${eventId}`
                )
              "
              :src="product['image_url']"
              :title="product['name']"
              :barista="product['baristas'][0]" />
          </li>
        </ul>
      </div> -->

      <div
        v-if="eventSchedules && eventSchedules.length > 0"
        class="c-section c-section--mb32">
        <h3 class="c-title6">開催日時</h3>

        <ul class="c-list">
          <li
            v-for="schedule in eventSchedules"
            :key="schedule.index"
            class="c-list__item">
            <span>
              {{ getPeriod(schedule['start_at'], schedule['end_at']) }}
            </span>
          </li>
        </ul>
      </div>

      <div v-if="content['venue_name']" class="c-section c-section--mb24">
        <h3 class="c-title6">開催場所</h3>

        <ul class="c-list">
          <li class="c-list__item">{{ content['venue_description'] }}</li>
          <li class="c-list__item">
            <a v-if="googleMapUrl" :href="googleMapUrl" class="c-addressAnchor">
              <Icon name="pin" />
              <span>
                {{ content['venue_name'] }}
              </span>
            </a>
            <template v-else>
              <Icon name="pin" />
              <span>
                {{ content['venue_name'] }}
              </span>
            </template>
          </li>
        </ul>
      </div>

      <div v-if="content['caution']" class="c-section c-section--mb32">
        <h3 class="c-title6">注意事項</h3>

        <p class="event-detail__caution" v-html="content['caution']" />
      </div>
    </template>
  </div>
</template>

<script>
import { RewardType, SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  computed,
  onMounted
} from '@vue/composition-api';
import { useDate } from '@/composables/useDate';
const { localizeDateTime, localizeTime, isSame } = useDate();

export default defineComponent({
  setup: (props, context) => {
    const content = ref(undefined);
    const eventId = ref(undefined);
    const additionalImagesOptions = {
      slidesPerView: 1.5,
      freeMode: true
    };

    const eventSchedules = computed(() => content.value['schedules']);

    /**
     * インセンティブの単位を取得する
     * @returns {string}
     */
    const reward = computed(() => content.value['reward']);

    /**
     * インセンティブの単位を取得する
     * @returns {string}
     */
    const rewardTypeUnit = computed(() => getRewardTypeUnit(1)); // ローンチ時ではマイルのみなので1固定

    /**
     * インセンティブの合計値取得する
     * @returns {number}
     */
    const rewardValue = computed(() => {
      // type = 1 （マイル）のときのみ加算し合計値を返す
      if (Array.isArray(reward.value)) {
        const amounts = reward.value.map((reward) =>
          reward.type === 1 ? reward.amount : 0
        );
        const reducer = (prevValue, currentValue) => prevValue + currentValue;
        const values = amounts.reduce(reducer);
        return values || 0;
      } else {
        return reward.value?.type === 1 ? reward.value?.amount : 0;
      }
    });

    /**
     * Google Map APIのURLを取得する
     * @see https://developers.google.com/maps/documentation/urls/get-started?hl=ja
     * @returns {string}
     */
    const googleMapUrl = computed(() => {
      const base = 'https://www.google.com/maps/search/?api=1&query=';
      const address = content.value?.['venue_address'];
      const latitude = content.value?.['venue_latitude'];
      const longitude = content.value?.['venue_longitude'];
      let query = '';
      // 開催場所の住所がある場合
      if (address) {
        query = address;
      } else if (latitude && longitude) {
        query = latitude + ',' + longitude;
      } else {
        return undefined;
      }
      return encodeURI(base + query);
    });

    const fetchData = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('contentJoin')
          .getEvent(eventId.value);
        content.value = data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    /**
     * 開催期間を取得する
     * @param {string} start
     * @param {string} end
     * @returns {string}
     */
    const getPeriod = (start, end) => {
      const startDateTime = localizeDateTime(start);
      const endDateTime = localizeDateTime(end);
      const endTime = localizeTime(end);

      return isSame(startDateTime, endDateTime)
        ? startDateTime + ' 〜 ' + endTime
        : startDateTime + ' 〜 ' + endDateTime;
    };

    const getRewardTypeUnit = (rewardType) =>
      RewardType.getTypeUnit(rewardType);

    eventId.value = context.root.$route.params.id || '';

    onMounted(() => {
      fetchData();
    });

    return {
      content,
      eventSchedules,
      additionalImagesOptions,
      getPeriod,
      rewardValue,
      googleMapUrl,
      rewardTypeUnit,
      eventId
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.event-detail__slide {
  @include mixin.imageAspectRatio(280, 160);

  overflow: hidden;
}

.event-detail__mile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  color: variables.$secondary01;
  font-size: 18px;
  line-height: 1;

  strong {
    margin-right: 4px;
    font-size: 30px;
    font-weight: bold;
  }

  span {
    padding-top: 4px;
  }
}

.event-detail__caution {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
